import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import Questionnaire, { QuestionnaireData, QuestionnaireResult } from '@/types/questionnaire/questionnaire'

export const questionnaireStore = defineStore('questionnaire', () => {
  const loading = ref(false)
  const questionnaires = ref<Questionnaire[]>([
    {
      id: 1,
      slug: 'sales',
      data: require('@/assets/json/questionnaire/sales.json'),
      completed: false
    },
    {
      id: 2,
      slug: 'pre-selection',
      data: require('@/assets/json/questionnaire/pre-selection.json'),
      completed: false
    },
    {
      id: 3,
      slug: 'development',
      data: require('@/assets/json/questionnaire/development.json'),
      completed: false
    },
    {
      id: 4,
      slug: 'delivery',
      data: require('@/assets/json/questionnaire/delivery.json'),
      completed: false
    },
    {
      id: 5,
      slug: 'service',
      data: require('@/assets/json/questionnaire/service.json'),
      completed: false
    }
  ])
  const started = ref(false)
  const completed = ref(false)
  const answered = ref(false)
  const results = ref<QuestionnaireResult[]>([])
  // const answers = ref<AnswerData[]>([])
  const openAnswerChecked = ref(false)
  const customAnswer = ref('')
  const reasoning = ref('')
  const currentQuestionIndex = ref(0)
  const currentQuestionnaireIndex = ref(0)

  const currentQuestionnaireId = computed(() => {
    if (results.value[currentQuestionnaireIndex.value]) {
      return results.value[currentQuestionnaireIndex.value].id
    }
    return null
  })

  const answers = computed(() => {
    if (results.value[currentQuestionnaireIndex.value]) {
      return results.value[currentQuestionnaireIndex.value].answers
    }
    return []
  })

  const numberOfQuestions = computed(() => {
    if (questionnaires.value[currentQuestionnaireIndex.value]) {
      return questionnaires.value[currentQuestionnaireIndex.value].data.questions.length
    }
    return 0
  })

  const isLastQuestion = computed(() => {
    return currentQuestionIndex.value + 1 === numberOfQuestions.value
  })

  const questionRequired = computed(() => {
    const required = questionnaires.value[currentQuestionnaireIndex.value].data.questions[currentQuestionIndex.value].required
    return required === undefined ? true : required
  })

  const questionResult = computed(() => {
    return results.value[currentQuestionnaireIndex.value].answers[currentQuestionIndex.value].answer
  })

  const disableNext = computed(() => {
    return results.value[currentQuestionnaireIndex.value].answers[currentQuestionIndex.value].answer.length <= 0 && questionRequired.value
  })

  const questionnaireData = computed(() => {
    if (currentQuestionnaireIndex.value >= 0) {
      return questionnaires.value[currentQuestionnaireIndex.value].data
    }

    return new QuestionnaireData()
  })

  const questionAnswers = computed(() => {
    return questionnaireData.value.questions[currentQuestionIndex.value].answers
  })

  const currentQuestion = computed(() => {
    return questionnaireData.value.questions[currentQuestionIndex.value]
  })

  function reset () : void {
    started.value = false
    completed.value = false
    currentQuestionIndex.value = 0
  }

  function start () {
    started.value = true
    const answerArray:string[] = results.value[currentQuestionnaireIndex.value].answers[currentQuestionIndex.value].answer as string[]
    const index = answerArray.findIndex(a => a.includes('Anders:'))
    if (index >= 0) {
      updateOpenAnswerChecked(true)
      answerArray.forEach((a: string) => {
        if (a.includes('Anders:')) {
          const customAnswer = a.replace('Anders: ', '')
          updateCustomAnswer(customAnswer)
        }
      })
    } else {
      updateOpenAnswerChecked(false)
      updateCustomAnswer('')
      updateReasoning('')
    }
  }

  function complete () : void {
    completed.value = true
  }

  function initResults (r:QuestionnaireResult[]) : void {
    results.value = r
  }

  function updateResult (answer:(string|number)[]) : void {
    results.value[currentQuestionnaireIndex.value].answers[currentQuestionIndex.value].answer = answer
  }

  function currentQuestionnaire (index:number) : void {
    currentQuestionnaireIndex.value = index
  }

  function updateOpenAnswerChecked (checked:boolean) : void {
    openAnswerChecked.value = checked
  }

  function updateCustomAnswer (val:string) : void {
    customAnswer.value = val
  }

  function updateReasoning (reason:string) : void {
    reasoning.value = reason
  }

  function nextQuestion () : void {
    if (currentQuestionIndex.value < numberOfQuestions.value - 1) {
      currentQuestionIndex.value++
      if (currentQuestion.value.type === 'multiple') {
        const answerArray: string[] = results.value[currentQuestionnaireIndex.value].answers[currentQuestionIndex.value].answer as string[]
        const index = answerArray.findIndex(a => a.includes('Anders:'))
        if (index >= 0) {
          updateOpenAnswerChecked(true)
          answerArray.forEach((a: string) => {
            if (a.includes('Anders:')) {
              const customAnswer = a.replace('Anders: ', '')
              updateCustomAnswer(customAnswer)
            }
          })
        } else {
          updateOpenAnswerChecked(false)
          updateCustomAnswer('')
          updateReasoning('')
        }
      }
    }
  }

  function previousQuestion () : void {
    if (currentQuestionIndex.value > 0) {
      currentQuestionIndex.value--
      if (currentQuestion.value.type === 'multiple') {
        const answerArray:string[] = results.value[currentQuestionnaireIndex.value].answers[currentQuestionIndex.value].answer as string[]
        const index = answerArray.findIndex(a => a.includes('Anders:'))
        if (index >= 0) {
          updateOpenAnswerChecked(true)
          answerArray.forEach((a:string) => {
            if (a.includes('Anders:')) {
              const customAnswer = a.replace('Anders: ', '')
              updateCustomAnswer(customAnswer)
            }
          })
        }
      }
    } else {
      reset()
    }
  }

  function markAsCompleted () : void {
    completed.value = true
    started.value = false
    questionnaires.value[currentQuestionnaireIndex.value].completed = true
  }

  return {
    loading,
    questionnaires,
    started,
    completed,
    answered,
    results,
    openAnswerChecked,
    customAnswer,
    reasoning,
    currentQuestionnaireIndex,
    currentQuestionIndex,
    currentQuestionnaireId,
    answers,
    numberOfQuestions,
    isLastQuestion,
    questionRequired,
    questionResult,
    disableNext,
    questionnaireData,
    questionAnswers,
    currentQuestion,
    reset,
    start,
    complete,
    initResults,
    updateResult,
    currentQuestionnaire,
    updateOpenAnswerChecked,
    updateCustomAnswer,
    updateReasoning,
    nextQuestion,
    previousQuestion,
    markAsCompleted
  }
})
